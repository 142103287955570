import axios from "axios";


function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

function layoutStabilizer(setListener = true) {
    let viewHeight = window.visualViewport.height;
    let viewWidth = window.visualViewport.width;
    let viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute("content", `height=${viewHeight}, width=${viewWidth}, initial-scale=1.0, user-scalable=0`);

    let style = document.createElement('style');
    style.id = 'layout_stabilizer';
    style.innerHTML = (/*css*/`
        .min-vh-100 { min-height: ${viewHeight}px !important; } 
        .min-vw-100 { min-width: ${viewWidth}px !important; } 
        .vh-100 { height: ${viewHeight}px !important; } 
        .vw-100 { width: ${viewWidth}px !important; }
    `);

    if (document.getElementById(style.id)) {
        document.getElementById(style.id).innerHTML = style.innerHTML;
    } else {
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    if (setListener) {
        window.addEventListener('resize', () => layoutStabilizer(false), true);
    }
}

function searchCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}

function searchCnpj(cnpj) {
    return axios.get(`https://publica.cnpj.ws/cnpj/${cnpj}`);
}

function currencyFormat(value, signDisplay=false) {

    return new Intl.NumberFormat('pt-BR', { signDisplay: signDisplay ? 'aways' : 'never', minimumFractionDigits: 2 }).format(value);
}

export {
    buildFormData,
    layoutStabilizer,
    searchCep,
    searchCnpj,
    currencyFormat
}