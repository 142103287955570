import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap"
import { vMaska } from "maska"

router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        !store.getters.isAuthenticated ? next() : next(from.path);
    } else if (to.path === "/signup") {
        !store.getters.isAuthenticated ? next() : next(from.path);
    } else if (to.path === "/recovery") {
        !store.getters.isAuthenticated ? next() : next(from.path);
    } else {
        store.getters.isAuthenticated ? next() : next("/login");
    }
});

const app = createApp(App)

app.use(router)
app.use(store);

app.directive("maska", vMaska)

app.mount('#app')
