<script setup lang="ts">
import { layoutStabilizer } from './mixins/helper';

layoutStabilizer();
</script>

<template>
  <div class="vh-100 bg-dark">
    <router-view />
  </div>
</template>


<style lang="scss">
@import "assets/scss/app.scss";
</style>