import { createApp } from "vue"
import App from '../App.vue'
import Vuex from 'vuex'

import auth from "./auth";
import investment from "./investment";

const app = createApp(App)

export default new Vuex.Store({
    modules: {
        auth,
        investment
    }

})