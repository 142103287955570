import ApiService from "@/common/api.service";

import { buildFormData } from "@/mixins/helper";

import {
    STORE,
    INDEX,
    ASSETS,
    DELETE,
    UPDATE,
} from "./actions.type";

const actions = {
    [INDEX](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/investment/index")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [ASSETS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/investment/assets")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [INDEX](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/investment/index")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [STORE](context, params) {

        let formData = new FormData();
        buildFormData(formData, params);

        return new Promise((resolve, reject) => {
            ApiService.post("/investment/store", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("/investment/delete/" + params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE](context, params) {

        let formData = new FormData();
        buildFormData(formData, params);

        return new Promise((resolve, reject) => {
            ApiService.post("/investment/update/" + params.id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};

export default {
    actions,
};