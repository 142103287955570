import cookie from 'vue-cookies'
import pkg from '@/../package.json';

const COOKIE_NAME = `${pkg.name}_token`;

export const getToken = () => {
    return cookie.get(COOKIE_NAME);
};

export const setToken = token => {
    cookie.set(COOKIE_NAME, token, '1h');
};

export const destroyToken = () => {
    cookie.remove(COOKIE_NAME);
};

export default { getToken, setToken, destroyToken };
export { COOKIE_NAME }